import React, { useRef, useCallback } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import LayoutCreate from "../../components/Layouts/Create";
import Form from "./components/Form";

import Request from "../../services/request";
import { listTasksRoute, showTaskRoute } from "../../routes/config";
import { taskSchedulerRouteApi } from "../../routes/config/api";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";
import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";

export const Create: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { loading, setLoading } = useGlobalLoading();

  const formatDate = useCallback((date: string) => {
    const [day, month, year] = date.split('/');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }, []);

  const createTask = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const response = await Request.post(taskSchedulerRouteApi.path, {
          ...data,
          type: 'email',
          frequency: 'daily',
          validate_user: false,
          module_id: data.module,
          user_ids: data.users,
          start_date: formatDate(data.start_date),
          end_date: formatDate(data.end_date),
        });

        toast.success("Tarefa criada com sucesso!");
        history.push(showTaskRoute.build({ id: response.id }));
      } catch (err) {
        let message = "Ops, não foi possível cadastrar esta tarefa!";

        if (!!err.response?.data?.message?.task_scheduler) {
          message = "Ops, não é possível criar tarefas para as datas informadas!";
        }

        toast.error(message);
      } finally {
        setLoading(false);
      }
    },
    [formatDate, history, setLoading]
  );

  const handleCreate = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;

      Object.assign(data, {
        days: !!data.days?.length
          ? data.days.map((day: any) => day.value).join(',')
          : undefined,
        module: !!data.module?.value
          ? Number(data.module?.value)
          : undefined,
        users: !!data.users?.length
          ? data.users.map((user: any) => user.value).join(',')
          : undefined,
      });

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo Obrigatório"),
        start_date: Yup.string().required("Campo Obrigatório"),
        end_date: Yup.string().required("Campo Obrigatório"),
        hour: Yup.string().required("Campo Obrigatório"),
        days: Yup.string().required("Campo Obrigatório"),
        module: Yup.number().required("Campo Obrigatório"),
        users: Yup.string().required("Campo Obrigatório"),
      });

      await schema.validate(data, { abortEarly: false });

      createTask(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createTask]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: "Tarefas", path: listTasksRoute.path },
        { text: "Adicionar Tarefa" },
      ]}
      footerActionsProps={{
        onSubmit: handleCreate,
        onCancelRoute: listTasksRoute.path,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutCreate>
  );
};
